import {getCookie} from "@/common/tool.js";
import {viewsStore} from "@/store/index.js";

const route = [
    {
        path: '/',
        component: () => import('@/pages/Index.vue'),
        meta: {auth: true}
    },
    {
        path: '/auth',
        component: () => import('@/pages/Auth.vue'),
        meta: {auth: false}
    },
    {
        path: '/book',
        component: () => import('@/pages/Book.vue'),
        meta: {auth: true}
    },
    {
        path: '/moment',
        component: () => import('@/pages/moment/Index.vue'),
        meta: {auth: true}
    },
    {
        path: '/moment/detail',
        component: () => import('@/pages/moment/Detail.vue'),
        meta: {auth: true}
    },
    {
        path: '/moment/create',
        component: () => import('@/pages/moment/Create.vue'),
        meta: {auth: true}
    },
    {
        path: '/ucenter',
        component: () => import('@/pages/ucenter/Index.vue'),
        meta: {auth: true}
    },
    {
        path: '/ucenter/moment',
        component: () => import('@/pages/ucenter/Moment.vue'),
        meta: {auth: true}
    },
    {
        path: '/ucenter/children',
        component: () => import('@/pages/ucenter/Children.vue'),
        meta: {auth: true}
    },
]
route.map((item, index) => {
    if (item.meta.auth) {
        item.beforeEnter = async (to, from, next) => {
            if (!getCookie('token')) {
                next(`/auth`)
            } else {
                const store = viewsStore()
                if (!store.user_info.id) {
                    await store.setUserInfo()
                }
                next();
            }
        }
    }
})

export default [
    {
        path: "/",
        component: () => import('@/layout/Pages.vue'),
        children: route,
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'Error',
        component: () => import('@/pages/Error.vue'),
        meta: {title: '错误'},
    }
]
