import {defineStore} from 'pinia'
import {user} from "@/api"

export const viewsStore = defineStore('views', {
    state: () => {
        return {
            config: {
                photo_link: "",
                photo_link2: "",
            },
            token: "",
            user_info: {},
            activity_list: [],
            session_list: [],
        }
    },
    actions: {
        async setUserInfo() {
            const res = await user.info();
            this.user_info = res.user_info;
        },
        setToken(token) {
            this.token = token;
        },
        setConfig(config) {
            this.config = config;
        },
        setActivityList(activity_list) {
            this.activity_list = activity_list;
        },
        setSessionList(session_list) {
            this.session_list = session_list;
        }
    }
})
