import {Toast, Dialog, Picker, Popup, setToastDefaultOptions} from "vant"
import 'vant/lib/toast/style';
import 'vant/lib/dialog/style';
import 'vant/lib/picker/style';
import 'vant/lib/popup/style';

setToastDefaultOptions({
    forbidClick: true,
})
setToastDefaultOptions('loading', {
    duration: 0,
})

export const installVant = (app) => {
    app.use(Toast);
    app.use(Dialog);
    app.use(Picker);
    app.use(Popup);
}
