import {getCookie} from "@/common/tool.js";

export default [
    {
        path: "/admin",
        component: () => import('@/layout/admin/Admin.vue'),
        children: [
            {
                path: '/admin',
                component: () => import('@/pages/admin/dashboard/Index.vue'),
                meta: {title: '控制台'},
            },
            {
                path: '/admin/activity',
                component: () => import('@/pages/admin/activity/Index.vue'),
                meta: {title: '活动管理'},
            },
            {
                path: '/admin/moment',
                component: () => import('@/pages/admin/moment/Index.vue'),
                meta: {title: '精彩瞬间'},
            },
            {
                path: '/admin/comment',
                component: () => import('@/pages/admin/comment/Index.vue'),
                meta: {title: '评论管理'},
            },
            {
                path: '/admin/user',
                component: () => import('@/pages/admin/user/Index.vue'),
                meta: {title: '用户管理'},
            },
            {
                path: '/admin/children',
                component: () => import('@/pages/admin/children/Index.vue'),
                meta: {title: '线上子女管理'},
            },
            {
                path: '/admin/children/offline',
                component: () => import('@/pages/admin/children/offline/Index.vue'),
                meta: {title: '线下子女管理'},
            },
            {
                path: '/admin/admin',
                component: () => import('@/pages/admin/admin/Index.vue'),
                meta: {title: '管理员'},
            },
            {
                path: '/admin/config',
                component: () => import('@/pages/admin/config/Index.vue'),
                meta: {title: '配置管理'},
            },
            {
                path: '/admin/config/config',
                component: () => import('@/pages/admin/config/Config.vue'),
                meta: {title: '系统配置'},
            },
            {
                path: '/admin/:pathMatch(.*)*',
                name: 'Error',
                component: () => import('@/pages/admin/Error.vue'),
                meta: {title: '错误'},
            }
        ],
        beforeEnter: (to, from, next) => {
            if (!getCookie('authorization')) {
                next('/admin/auth/login')
            } else {
                next();
            }
        }
    },
    {
        path: '/admin/auth/login',
        component: () => import('@/pages/admin/auth/Login.vue'),
        meta: {title: '登录'},
    },
]
