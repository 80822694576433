export const site_config = {
    title: import.meta.env.VITE_APP_TITLE,
    api: import.meta.env.DEV ? import.meta.env.VITE_APP_API_HOST : import.meta.env.VITE_APP_API_HOST,
}
export const ext_config = {
    file: ".pdf,.ppt,.doc,.docx,.word,.xlxs,.xls",
    image: ".jpg,.jpeg,.png,.gif,.svg,.tiff",
    video: ".mp4,.mov,.ogg",
}

export const site_option = {
    type: [
        {id: 2, title: "线上活动 Online activity"},
        {id: 1, title: "线下活动 Offline activity"},
    ],
    prize: [
        {id: 1, title: "Prize 1: AR实感游戏枪 AR Gun"},
        {id: 2, title: "Prize 2: 编程机器人 Programming robot"},
        {id: 3, title: "Prize 3: 魔法礼盒 Magic box"},
    ],
    traffic: [
        {id: 1, title: "大巴 Shuttle bus"},
        {id: 2, title: "自驾 Self-driving"},
    ],
    contestant_size: [
        {id: 1, title: "120#"},
        {id: 2, title: "130#"},
        {id: 3, title: "140#"},
        {id: 4, title: "150#"},
        {id: 5, title: "160#"},
        {id: 6, title: "170#"},
    ],
    finish_size: [
        {id: 1, title: "S"},
        {id: 2, title: "M"},
        {id: 3, title: "L"},
        {id: 4, title: "XL"},
        {id: 5, title: "2XL"},
    ]
}
