import jsCookie from "js-cookie";

/**
 * 加载js文件
 * @param src
 * @param async
 * @param options
 * @returns {Promise<unknown>}
 */
export const loadJsAsync = (src, async = true, options = {}) => {
	return new Promise((resolve, reject) => {
		const script = document.createElement("script");
		script.src = src;
		script.async = async;
		if (options) {
			for (const key in options) {
				script.setAttribute(key, options[key]);
			}
		}
		const onload = () => {
			script.removeEventListener("load", onload);
			resolve();
		};
		script.addEventListener("load", onload);
		script.addEventListener("error", (err) => {
			script.removeEventListener("load", onload);
			reject(new Error(`Failed to load ${src}`));
		});
		(document.getElementsByTagName("head")[0] || document.documentElement).appendChild(script);
	});
}

/**
 * 是否在数组中
 * @param array
 * @param key
 * @returns {boolean}
 */
export const inArray = (array, key) => {
	return array.indexOf(key) > -1
}

/**
 * 设置cookie
 * @param key
 * @param value
 * @param option
 */
export const setCookie = (key, value, option = {}) => {
	jsCookie.set(key, value, option)
}

/**
 * 获取cookie
 * @param key
 * @returns {*}
 */
export const getCookie = (key) => {
	return jsCookie.get(key);
}

/**
 * 0不足位补齐
 * @param num
 * @param n
 * @returns {*|*}
 */
export const makeNumber = (num, n = 0) => {
	var tbl = [];
	var thumb = function thumb() {
		var len = n - num.toString().length;
		if (len <= 0) return num;
		if (!tbl[len]) tbl[len] = new Array(len + 1).join('0');
		return tbl[len] + num;
	};
	return thumb(num.n);
}

/**
 * 将阿拉伯数字翻译成中文的大写数字
 * @param num
 * @returns {string}
 */
export const numberToChinese = (num) => {
	let AA = ["零", "一", "二", "三", "四", "五", "六", "七", "八", "九", "十"];
	let BB = ["", "十", "百", "千", "万", "亿", "点", ""];
	let a = ("" + num).replace(/(^0*)/g, "").split("."),
		k = 0,
		re = "";
	for (let i = a[0].length - 1; i >= 0; i--) {
		switch (k) {
			case 0:
				re = BB[7] + re;
				break;
			case 4:
				if (!new RegExp("0{4}//d{" + (a[0].length - i - 1) + "}$").test(a[0])) {
					re = BB[4] + re;
				}
				break;
			case 8:
				re = BB[5] + re;
				BB[7] = BB[5];
				k = 0;
				break;
		}
		if (k % 4 === 2 && a[0].charAt(i + 2) !== 0 && a[0].charAt(i + 1) === 0) {
			re = AA[0] + re;
		}
		if (a[0].charAt(i) !== 0) {
			re = AA[a[0].charAt(i)] + BB[k % 4] + re;
		}
		k++;
	}

	if (a.length > 1) {
		re += BB[6];
		for (let i = 0; i < a[1].length; i++) {
			re += AA[a[1].charAt(i)];
		}
	}
	if (re === '一十') {
		re = "十";
	}
	if (re.match(/^一/) && re.length === 3) {
		re = re.replace("一", "");
	}
	return re;
}

/**
 * 创建唯一uuid
 * @returns {string}
 */
export const createUUID = () => {
	let s = [];
	let hexDigits = "0123456789abcdef";
	for (let i = 0; i < 36; i++) {
		s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
	}
	s[14] = "4";
	s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1);
	s[8] = s[13] = s[18] = s[23] = "-";

	return s.join("");
}

/**
 * 获取uuid
 * @returns {string}
 */
export const getUUID = () => {
	let uuid = localStorage.getItem('UUID');
	if (!uuid) {
		uuid = createUUID();
		localStorage.setItem('UUID', uuid);
	}
	return uuid;
}

/**
 * 时间戳格式化
 * @param time
 * @param format
 * @returns {*|string}
 */
export const timeToString = (time, format = 'YYYY-MM-DD HH:mm:ss') => {
	return time ? moment.unix(time).format(format) : '';
}

/**
 * 获取滚动条宽度
 * @returns {number}
 */
export const getScrollbarWidth = () => {
	const scrollDiv = document.createElement('div');
	scrollDiv.style.cssText = 'width: 99px; height: 99px; overflow: scroll; position: absolute; top: -9999px;';
	document.body.appendChild(scrollDiv);
	const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
	document.body.removeChild(scrollDiv);
	return scrollbarWidth;
}

/**
 * 设置rem
 */
export const setRem = () => {
	const __resize = () => {
		const clientWidth = document.documentElement.clientWidth;
		document.documentElement.style.fontSize = clientWidth / 7.5 + 'px';
	}
	window.addEventListener("resize", () => {
		setTimeout(() => {
			__resize();
		}, 100);
		setTimeout(() => {
			__resize();
		}, 300);
	}, false);
	__resize();
}

/**
 * 设置字体大小
 * 处理微信字体缩放
 */
export const setFont = () => {
	let dom = document.createElement('div');
	dom.getRootNode().style = 'font-size: 10px';
	document.body.appendChild(dom);
	let scaledFontSize = parseInt(window.getComputedStyle(dom, null).getPropertyValue('font-size'));
	document.body.appendChild(dom);
	let scaleFactor = 10 / scaledFontSize;
	let originRootFontSize = parseInt(window.getComputedStyle(document.documentElement, null).getPropertyValue('font-size'));
	document.documentElement.style.fontSize = originRootFontSize * scaleFactor * scaleFactor + 'px';
}

/**
 * 是否开发环境
 * @returns {boolean}
 */
export const isDev = () => {
	return process.env.NODE_ENV === 'development'
}

/**
 * 是否调试环境
 * @returns {boolean}
 */
export const isDebug = () => {
	return !!getUrlParams('debug');
}

/**
 * 滚动到指定位置
 * @param number
 * @param time
 * @returns {number}
 */
export const scrollTop = (number = 0, time = 0) => {
	if (!time) {
		document.body.scrollTop = document.documentElement.scrollTop = number;
		return number;
	}
	var spacingTime = 20; // 设置循环的间隔时间  值越小消耗性能越高
	var spacingIndex = time / spacingTime; // 计算循环的次数
	var nowTop = document.body.scrollTop + document.documentElement.scrollTop; // 获取当前滚动条位置
	var everTop = (number - nowTop) / spacingIndex; // 计算每次滑动的距离
	var scrollTimer = setInterval(() => {
		if (spacingIndex > 0) {
			spacingIndex--;
			this.scrollTop(nowTop += everTop);
		} else {
			clearInterval(scrollTimer); // 清除计时器
		}
	}, spacingTime);
}


/**
 * 获取16进制随机颜色
 * @returns {string}
 */
export const getRandomColor = () => {
	return '#' + (function (h) {
		return new Array(7 - h.length).join("0") + h;
	})((Math.random() * 0x1000000 << 0).toString(16));
}

/**
 * 移除表情
 * @param str
 * @returns {*}
 */
export const removeEmoji = (str) => {
	return str.replace(/[\ud800-\udbff][\udc00-\udfff]/g, '')
}

/**
 * 过滤html代码
 * @param str
 * @returns {*}
 */
export const filterTag = (str) => {
	str = str.replace(/&/ig, "&amp;");
	str = str.replace(/</ig, "&lt;");
	str = str.replace(/>/ig, "&gt;");
	str = str.replace(" ", "&nbsp;");
	return str;
}

/**
 * 是否假值
 * @param o
 * @returns {boolean}
 */
export const isFalse = (o) => {
	return (o === '' || o === undefined || o === null || o === 'null' || o === 'undefined' || o === 0 || o === false || isNaN(o));
}

/**
 * 是否真值
 */
export const isTrue = (o) => {
	return !!isFalse(o)
}

/**
 * 是否移动环境
 * @param ua
 * @returns {boolean}
 */
export const isMobile = (ua) => {
	ua = navigator?.userAgent || '';
	return /mobile|android|iphone|ipad|phone/i.test(ua.toLowerCase())
}

/**
 * 是否微信
 * @param ua
 * @returns {boolean}
 */
export const isWeiXin = (ua) => {
	ua = navigator?.userAgent || '';
	return /micromessenger/i.test(ua.toLowerCase())
}

/**
 * 是否IOS
 * @param ua
 * @returns {boolean}
 */
export const isIos = (ua) => {
	ua = navigator?.userAgent || '';
	if (ua.indexOf('Android') > -1 || ua.indexOf('Linux') > -1) {//安卓手机
		return false
	} else if (ua.indexOf('iPhone') > -1) {//苹果手机
		return true
	} else if (ua.indexOf('iPad') > -1) {//iPad
		return false
	} else if (ua.indexOf('Windows Phone') > -1) {//winphone手机
		return false
	} else {
		return false
	}
}


/**
 * 常见字符校验
 * @param str
 * @param type
 * @returns {boolean}
 */
export const checkString = (str, type) => {
	switch (type) {
		case 'phone':   //手机号码
			return /^1[0-9]{10}$/.test(str);
		case 'tel':     //座机
			return /^(0\d{2,3}-\d{7,8})(-\d{1,4})?$/.test(str);
		case 'card':    //身份证
			// 1 "验证通过!", 0 //校验不通过 // id为身份证号码
			var format = /^(([1][1-5])|([2][1-3])|([3][1-7])|([4][1-6])|([5][0-4])|([6][1-5])|([7][1])|([8][1-2]))\d{4}(([1][9]\d{2})|([2]\d{3}))(([0][1-9])|([1][0-2]))(([0][1-9])|([1-2][0-9])|([3][0-1]))\d{3}[0-9xX]$/;
			//号码规则校验
			if (!format.test(str)) {
				return false;
			}
			//区位码校验
			//出生年月日校验  前正则限制起始年份为1900;
			var year = str.substr(6, 4),//身份证年
				month = str.substr(10, 2),//身份证月
				date = str.substr(12, 2),//身份证日
				time = Date.parse(month + '-' + date + '-' + year),//身份证日期时间戳date
				now_time = Date.parse(new Date()),//当前时间戳
				dates = (new Date(year, month, 0)).getDate();//身份证当月天数
			if (time > now_time || date > dates) {
				return false;
			}
			//校验码判断
			var c = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2];  //系数
			var b = ['1', '0', 'X', '9', '8', '7', '6', '5', '4', '3', '2']; //校验码对照表
			var id_array = str.split("");
			var sum = 0;
			for (var k = 0; k < 17; k++) {
				sum += parseInt(id_array[k]) * parseInt(c[k]);
			}
			if (id_array[17].toUpperCase() !== b[sum % 11].toUpperCase()) {
				return false;
			}
			return true;
		case 'pwd':     //密码以字母开头，长度在6~18之间，只能包含字母、数字和下划线
			return /^[a-zA-Z]\w{5,17}$/.test(str)
		case 'postal':  //邮政编码
			return /[1-9]\d{5}(?!\d)/.test(str);
		case 'QQ':      //QQ号
			return /^[1-9][0-9]{4,10}$/.test(str);
		case 'email':   //邮箱
			return /^[\w-]+(\.[\w-]+)*@[\w-]+(\.[\w-]+)+$/.test(str);
		case 'money':   //金额(小数点2位)
			return /^\d*(?:\.\d{0,2})?$/.test(str);
		case 'URL':     //网址
			return /(http|ftp|https):\/\/[\w\-_]+(\.[\w\-_]+)+([\w\-\.,@?^=%&:/~\+#]*[\w\-\@?^=%&/~\+#])?/.test(str)
		case 'IP':      //IP
			return /((?:(?:25[0-5]|2[0-4]\\d|[01]?\\d?\\d)\\.){3}(?:25[0-5]|2[0-4]\\d|[01]?\\d?\\d))/.test(str);
		case 'date':    //日期时间
			return /^(\d{4})\-(\d{2})\-(\d{2}) (\d{2})(?:\:\d{2}|:(\d{2}):(\d{2}))$/.test(str) || /^(\d{4})\-(\d{2})\-(\d{2})$/.test(str)
		case 'number':  //数字
			return /^[0-9]$/.test(str);
		case 'english': //英文
			return /^[a-zA-Z]+$/.test(str);
		case 'chinese': //中文
			return /^[\u4E00-\u9FA5]+$/.test(str);
		case 'lower':   //小写
			return /^[a-z]+$/.test(str);
		case 'upper':   //大写
			return /^[A-Z]+$/.test(str);
		case 'HTML':    //HTML标记
			return /<("[^"]*"|'[^']*'|[^'">])*>/.test(str);
		default:
			return true;
	}
}

/**
 * 严格的身份证校验
 * @param sId
 * @returns {boolean}
 */
export const isCardID = (sId) => {
	if (!/(^\d{15}$)|(^\d{17}(\d|X|x)$)/.test(sId)) {
		//'你输入的身份证长度或格式错误'
		return false
	}
	//身份证城市
	var aCity = {
		11: "北京",
		12: "天津",
		13: "河北",
		14: "山西",
		15: "内蒙古",
		21: "辽宁",
		22: "吉林",
		23: "黑龙江",
		31: "上海",
		32: "江苏",
		33: "浙江",
		34: "安徽",
		35: "福建",
		36: "江西",
		37: "山东",
		41: "河南",
		42: "湖北",
		43: "湖南",
		44: "广东",
		45: "广西",
		46: "海南",
		50: "重庆",
		51: "四川",
		52: "贵州",
		53: "云南",
		54: "西藏",
		61: "陕西",
		62: "甘肃",
		63: "青海",
		64: "宁夏",
		65: "新疆",
		71: "台湾",
		81: "香港",
		82: "澳门",
		91: "国外"
	};
	if (!aCity[parseInt(sId.substr(0, 2))]) {
		// '你的身份证地区非法'
		return false
	}

	// 出生日期验证
	var sBirthday = (sId.substr(6, 4) + "-" + Number(sId.substr(10, 2)) + "-" + Number(sId.substr(12, 2))).replace(/-/g, "/"),
		d = new Date(sBirthday)
	if (sBirthday !== (d.getFullYear() + "/" + (d.getMonth() + 1) + "/" + d.getDate())) {
		//'身份证上的出生日期非法'
		return false
	}

	// 身份证号码校验
	var sum = 0,
		weights = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2],
		codes = "10X98765432"
	for (var i = 0; i < sId.length - 1; i++) {
		sum += sId[i] * weights[i];
	}
	var last = codes[sum % 11]; //计算出来的最后一位身份证号码
	return sId[sId.length - 1] === last;
}

/**
 * 获取url参数
 * @param name
 * @returns {string|null}
 */
export const getUrlParams = (name) => {
	var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
	var r = decodeURI(window.location.search).substr(1).match(reg);
	if (r != null) return r[2];
	return null;
}

/**
 * 设置url参数
 * @param url
 * @param arg
 * @param arg_val
 * @returns {string|*|string}
 */
export const setUrlParams = (url, arg, arg_val) => {
	let pattern = arg + '=([^&]*)';
	let replaceText = arg + '=' + arg_val;
	if (url.match(pattern)) {
		let tmp = '/(' + arg + '=)([^&]*)/gi';
		tmp = url.replace(eval(tmp), replaceText);
		return tmp;
	} else {
		if (url.match('[\?]')) {
			return url + '&' + replaceText;
		} else {
			return url + '?' + replaceText;
		}
	}
}

/**
 * 获取全部url参数的json对象
 * @param url
 * @returns {{}}
 */
export const getUrlAllParams = (url) => {
	var _pa = url.substring(url.indexOf('?') + 1),
		_arrS = _pa.split('&'),
		_rs = {};
	for (var i = 0, _len = _arrS.length; i < _len; i++) {
		var pos = _arrS[i].indexOf('=');
		if (pos === -1) {
			continue;
		}
		var name = _arrS[i].substring(0, pos);
		_rs[name] = decodeURIComponent(_arrS[i].substring(pos + 1));
	}
	return _rs;
}

/**
 * 删除url指定参数，返回url
 * @param url
 * @param name
 * @returns {string|*}
 */
export const deleteUrlParam = (url, name) => {
	let baseUrl = url.split('?')[0] + '?';
	let query = url.split('?')[1];
	if (query.indexOf(name) > -1) {
		let obj = {}
		let arr = query.split("&");
		for (let i = 0; i < arr.length; i++) {
			arr[i] = arr[i].split("=");
			obj[arr[i][0]] = arr[i][1];
		}
		delete obj[name];
		url = baseUrl + JSON.stringify(obj).replace(/["{}]/g, "").replace(/:/g, "=").replace(/,/g, "&");
		return url
	} else {
		return url;
	}
}

/**
 * 对象转url参数
 * @param data
 * @param isPrefix
 * @returns {string|string}
 */
export const ObjectToParam = (data, isPrefix = false) => {
	let prefix = isPrefix ? '?' : ''
	let _result = []
	for (let o in data) {
		let value = data[o]
		// 去掉为空的参数
		if (['', undefined, null].includes(value)) {
			continue
		}
		if (value.constructor === Array) {
			value.forEach((_value) => {
				_result.push(encodeURIComponent(o) + '[]=' + encodeURIComponent(_value))
			})
		} else {
			_result.push(encodeURIComponent(o) + '=' + encodeURIComponent(value))
		}
	}
	return _result.length ? prefix + _result.join('&') : ''
}

