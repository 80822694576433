const Alphabet = {
    install: (app, option) => {
        app.directive('alphabet', {
            mounted: (el) => {
                el.onkeyup = (e) => {
                    el.value = el.value.replace(/[^A-Za-z0-9]/g, '')
                }
                el.onblur = (e) => {
                    el.value = el.value.replace(/[^A-Za-z0-9]/g, '')
                }
            }
        })
    }
}
export const directiveAlphabet = (app) => {
    app.use(Alphabet)
}
