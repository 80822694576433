import {request} from '@/common/request.js'

export const site = {
    upload(data) {
        return request.post('/api/upload/create', data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            timeout: 60 * 20 * 1000
        })
    },
}

export const wechat = {
    jssdk(params) {
        return request.get('/api/wechat/jssdk', {params})
    }
}

export const config = {
    collect() {
        return request.get('/api/config/collect')
    }
}

export const auth = {
    login(data) {
        return request.post('/api/auth/login', data)
    }
}

export const views = {
    log(data) {
        return request.post('/api/view/log', data)
    }
}

export const user = {
    info(params) {
        return request.get('/api/user/info', {params})
    },
}

export const article = {
    get(params) {
        return request.get('/api/article/get', {params})
    }
}

export const activity = {
    all(params) {
        return request.get('/api/activity/all', {params})
    },
    get(params) {
        return request.get('/api/activity/get', {params})
    }
}

export const activity_session = {
    all(params) {
        return request.get('/api/activity_session/all', {params})
    },
}

export const moment = {
    all(params) {
        return request.get('/api/moment/all', {params})
    },
    get(params) {
        return request.get('/api/moment/get', {params})
    },
    create(data) {
        return request.post('/api/moment/create', data)
    },
    like(data) {
        return request.post('/api/moment/like', data)
    }
}

export const children = {
    all(params) {
        return request.get('/api/children/all', {params})
    },
    create(data) {
        return request.post('/api/children/create', data)
    },
    update(data) {
        return request.post('/api/children/update', data)
    },
    delete(data) {
        return request.post('/api/children/delete', data)
    }
}

export const comment = {
    all(params) {
        return request.get('/api/comment/all', {params})
    },
    create(data) {
        return request.post('/api/comment/create', data)
    }
}

